import Vue from "vue";
export default {
    createQuotationBatch(productId, contacts, type){
        return new Promise((resolve, reject)=>{
            Vue.http.post("quotation/formation", {productId, contacts, type}).then((response)=>{
                resolve(response.body.response)
            }, reject)
        })
    },
    getQuotationBatchProgress(quotationBatchId){
        return new Promise((resolve, reject)=>{
            Vue.http.get(`quotation/batch/${quotationBatchId}`).then((response)=>{
                resolve(response.body.response)
            }, reject)
        })
    },
    getAll(){
        return new Promise((resolve, reject)=>{
            Vue.http.get("quotation/formation").then((response)=>{
                resolve(response.body.response)
            }, reject)
        })
    },
    createOrderFromQuotation(id){
        return new Promise((resolve, reject)=>{
            Vue.http.get(`quotation/formation/${id}/pay`).then((response)=>{
                resolve(response.body.response)
            }, reject)
        })
    },
    getLink(id){
        return new Promise((resolve, reject)=>{
            Vue.http.get(`quotation/formation/${id}/link`).then((response)=>{
                resolve(response.body.response)
            }, reject)
        })
    }
}