<template>
  <div class="m-training-item__details">
    <span data-icon="timer" class="icon-flex">
      {{ data.formation.duration.hours }}h
      {{
        data.startAt !== data.endAt && data.formation.duration.days > 1
          ? "sur " + data.formation.duration.days + " jours"
          : ""
      }}
    </span>
    <span
      data-icon="pin"
      v-if="data.city && data.format === 'instructor-led'"
      class="icon-flex"
      >Présentiel - {{ data.city }}</span
    >
    <span
      data-icon="people"
      v-if="
        (data.format === 'instructor-led' || data.format === 'webinar') && 
        !hideRemainingPlaces
      "
      class="icon-flex"
    >
      <span class="desktop right-space">Il reste {{ Math.max(0, data.remainingPlaces) }} {{data.remainingPlaces > 1 ? 'places' : 'place'}}</span>
    </span>
    <span
      data-icon="screen"
      v-if="data.format === 'e-learning' || data.format === 'webinar'"
      class="icon-flex"
    >
      <span v-if="data.format === 'webinar'">Webinaire</span>
      <span v-else class="capitalize">{{ data.format }}</span>
    </span>
  </div>
</template>
<script>
export default {
    props: ["data", "hide-remaining-places"],
}
</script>
