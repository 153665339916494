<template>
	<div class="a-maintenance" v-if="config.maintenance.enabled">
		<img src="/img/logo-snpi.svg">
		{{ config.maintenance.status }}
	</div>

</template>

<script>
	export default {
		name: "maintenance",
    computed:{
      config(){ return this.$config() }
    },
	}
</script>

<style lang="scss">
	@import '../../environment';
	.a-maintenance{
		display: flex; align-items: center; justify-content: center; position: overlay(); font-size: $font-m; flex-flow: column;
		letter-spacing: 1px; color: $c-primary; text-transform: uppercase; font-weight: bold;
	}
</style>