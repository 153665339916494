<template>
	<div class="o-documents-list container" :class="'o-documents-list--'+type+' o-documents-list--'+category" v-if="type">
		<div class="grid">
			<div class="content">
				<div class="block">
					<h1 class="title">
						{{ getTitle() }}
					</h1>
					<div class="side-padding">
						<div class="o-documents-list__top">
							<div class="o-documents-list__results">
								<span v-if="loading">Chargement...</span>
								<span v-else-if="count > 0">{{ count }} résultat<span v-if="count > 1">s</span></span>
								<span v-else>Pas de résultat</span>
							</div>
							<div class="o-documents-list__search">
								<form @submit.prevent="searchDocuments" id="documents_search">
									<button type="submit" class="button--search"></button>
									<input type="text" placeholder="Rechercher" name="search" v-model="params.search" minlength="3"/>
								</form>
							</div>
						</div>

						<documents-filters class="o-documents-list__filters" mod="list" @changeFilters="updateTerms" :taxonomy="getTaxonomy" @resetSearch="params.search = ''"/>

						<div v-if="!documents.length && !loading" class="o-documents-list__empty">
							<div v-if="this.params.filter === 'favorite'">
								<p class="title-big">Aucun document favori.</p>
								<p><b class="primary">Rechercher :</b></p>
								<ul>
									<li v-for="(item, index) in terms">
										<router-link :to="{ name: 'documents', params:{ section: item.slug, title: item.title }}" class="link">{{item.title}}</router-link>
									</li>
									<li>
										<router-link :to="{ name: 'documents', params:{ section: 'documents-administratifs' }}" class="link">Documents administratifs</router-link>
									</li>
								</ul>
							</div>
							<p class="title-big" v-else>Aucun document</p>
						</div>
						<div v-else>
							<div class="o-documents-list__header desktop" v-if="$route.params.section !== 'podcasts'">
								<div class="document-adaptative clickable" @click="sortBy('title')" :class="getClass('title')">Libellé</div>
								<div class="document-sw">Catégorie</div>
								<div class="document-sw" v-if="type !== 'appendix'">Nature</div>
								<div class="document-sw clickable" @click="sortBy('createdAt')" :class="getClass('createdAt')">Publié le</div>
								<div class="document-actions"></div>
							</div>
							<div class="o-documents-list__overflow" v-infinite-scroll.full="loadMore" ref="container">
								<div class="o-documents-list__item" :class="{ 'o-documents-list__item--active': preview === document}"  v-for="(document,index) in documents" :key="document.id">
                  <podcast :data="document" v-if="$route.params.section === 'podcasts'"></podcast>
                  <component :is="document.type" :data="document" mod="preview" v-else @preview="showPreview(document)"></component>
                </div>
								<loader v-if="loading" class="o-documents-list__loader"></loader>
							</div>
						</div>
					</div>

				</div>
			</div>

			<div class="aside desktop" v-if="$route.params.section !== 'podcasts'">
				<div class="block sticky aside--preview">
					<h1 class="title">Aperçu</h1>
					<div v-if="!preview">Veuillez sélectionner un document pour voir son aperçu</div>
					<div v-else-if="preview && !preview.thumbnail">Aucun aperçu disponible pour ce document</div>
					<document-preview :data="preview" v-else></document-preview>
          <clap-essai v-if="this.user.isLegalRepresentative"></clap-essai>
				</div>
			</div>
		</div>
	</div>
</template>

<script>

	import appendixRepository from '@/repositories/appendixRepository';
	import documentRepository from '@/repositories/documentRepository';
	import resourceRepository from "@/repositories/resourceRepository";
  import urlParams from "params-url";
  import editableDocumentRepository from "@/repositories/editableDocumentRepository";

	export default {
		name: "documents-list",
		props:['data'],
		data(){
			return{
				preview: false,
				loading: false,
				documents: [],
				count: -1,
				type: 'resource',
				params:{
					sort: 'createdAt',
					order: 'desc',
					limit : 20,
					offset: 0,
					search: '',
					category: [],
					filter: '',
					year: ''
				}
			}
		},
		methods:{
			getClass(type){

				return this.params.sort === type? 'order-'+this.params.order:''
			},
			getTitle(){

        let term = this.terms.find(term => term.slug === this.$route.params.section )

				if( term )
					return term.title;
				else if( this.$route.params.section === 'favoris')
					return 'Documents favoris';
				else if( this.$route.params.section )
					return this.$route.params.section.replace(/-/g, ' ')
			},
			getTaxonomy(){

				if( this.type==='document' )
					return 'category';

				if(this.type==='appendix')
					return 'administrative';

				return 'all';
			},
			sortBy(type){

				if(this.params.sort === type)
					this.params.order = this.params.order === 'desc' ? 'asc' : 'desc';
				else{
					this.params.sort = type;
					this.params.order = 'desc'
				}

				this.searchDocuments();
			},
			searchDocuments(){

				this.query(0);
			},
			updateTerms(category, form){

				if(category){
					switch(form.type){
						case 'documents-administratifs':
							this.type = 'appendix';
							break;
						case 'documents-a-signer':
							this.type = 'editable';
							break;
						default:
							this.type = 'document'
							break;
					}
					this.params.category = category;

					if(form.year){
						this.params.year = form.year
						this.params.filter = 'year'
					} else {
						this.params.year = ''
						this.params.filter = ''
					}
				} else{

					this.reset()
				}

				this.searchDocuments();
			},
			reset(){

				this.params.category = [];
				this.params.search = '';
				this.params.year = '';
				this.params.filter = '';

				if(this.$route.params.section && this.$route.params.section !== 'favoris'){

					if( this.$route.params.section === 'documents-administratifs' ){

						this.type = 'appendix';
					}
          else if( this.$route.params.section === 'documents-a-signer' ){

            this.type = 'editable';
          }
					else{

						this.type = 'document';
						this.params.category = [this.$route.params.section];
					}

				} else {

					this.type = 'resource';

					if( this.$route.params.section === 'favoris' )
            this.params.filter = 'favorite';
				}

				this.searchDocuments();
			},
			showPreview(item){
				this.preview = item;
			},
			success(response){
				this.params.offset = response.offset;
				this.count = response.count;
				this.documents = response.items;
				this.loading = false;
			},
			error(response){
				this.loading = false;
			},
			query(offset){

				if( this.loading === true )
					return;

				this.params.offset = offset;
				this.loading = true;

        if( this.type === 'document')
          documentRepository.list(this.params).then(this.success, this.error);
				else if( this.type === 'editable')
					editableDocumentRepository.list(this.params).then(this.success, this.error);
				else if( this.type === 'appendix')
					appendixRepository.list(this.params).then(this.success, this.error);
				else
					resourceRepository.list(this.params).then(this.success, this.error);
			},
			loadMore(){

				if( this.documents.length >= this.count || !this.documents.length )
					return false;

				this.query(this.params.offset + this.params.limit)

			}
		},
		computed:{
      config(){ return this.$config() },
      user(){ return this.$user() },
			terms(){
				return this.$store.getters.terms();
			},
			category(){
				return this.params.category.length ? this.params.category[0] : '';
			}
		},
		mounted(){
      if(this.params.category.length == 0)
        this.reset();
		}
	}
</script>

<style lang="scss">
	@import '../../environment';
	.o-documents-list{
    .o-clap-alert-ship__img-alert-new-system-container {
      position: sticky;
      top: 260px;
      height: 25rem;
      margin: 3rem 0 0 0;
      border-radius: 4px;
    }
		&__top{
			padding: $space-s $space $space; color: $c-primary
		}
		&__results{ display: block; margin-bottom: 1rem }
		&__search{ position: relative }
		&__overflow{
			position: relative; min-height: 20rem;
		}
		&__search{ margin-left: auto; position: relative }
		&__overflow{
			position: relative; min-height: 20rem;
			.accessible-version &{ border: solid 1px $c-border }

		}
		&__empty{
			padding: $space $space $space-m;

			ul{ margin-top : $space-m }

			li + li{ margin-top: $space-s }
		}

    &__item{
      &:nth-child(odd){ background: $c-background-lighter }
      .o-documents-list--podcasts &{
        &:nth-child(odd){ background: #fff }
        &:nth-child(even){ background: $c-background-lighter }
      }
    }

		&__header{
			background: $c-primary; color: white; display: flex; align-items: center; padding: $space-s $half-space;
			font-weight: bold; text-transform: uppercase; font-size: $font-xs;
			.document-mw{ text-align: right }
			[data-icon_after]{
				cursor: pointer;
				&:after{ margin-left: 0.5rem; display: inline-block }
				&.order-asc{ &:after{ transform: rotateX(180deg) }}
			}
		}
		@media #{$from-small}{
			.document-actions{ margin-left: 0; text-align: right }
		}
		.sticky{ position: sticky; top: 100px }
		&__download{ position:relative }
		&--appendix{
			.m-document--appendix .m-document__nature{ display: none }
		}
    &--podcasts .grid .content{ width: 100% }
		.o-documents-list__loader.a-loader{ background: rgba($c-background,0.4); top: auto; bottom: -3rem; }
	}

</style>
