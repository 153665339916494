<template>
	<div class="o-news-summary">
		<h2 class="o-news-summary__title title">{{ interface.title }}</h2>
		<div class="o-news-summary__scrollbox">
      <router-link to="/documents/podcasts" class="o-news-summary__podcasts" data-icon="headphones">Écouter les podcasts<span data-icon="chevron-right"></span></router-link>
      <!-- <router-link to="/snpi_tv" class="o-news-summary__tv" data-icon="television">Regarder MaTVIMMO<span data-icon="chevron-right"></span></router-link> -->
      <router-link to="/actualites" class="o-news-summary__all" data-icon="notification">Voir toutes les actus<span data-icon="chevron-right"></span></router-link>
      <div class="o-news-summary__pushes">
				<news v-for="(item, index) in news" :key="item.id" :data="item"></news>
        <router-link to="/actualites" class="button">Voir toutes les actus</router-link>
      </div>
    </div>
	</div>
</template>

<script>

	import newsRepository from '@/repositories/newsRepository';

	export default {
		name: 'news-summary',
		props:['data', 'interface'],
		data(){
			return{
				params:{
					limit : 10,
					offset: 0
				},
				count: -1,
				news: []
			}
		},
		computed:{
			user(){ return this.$user() }
		},
		methods:{
			query(offset){

				this.params.offset = offset;

				newsRepository.find(this.params).then(response => {
					this.count = response.count;
					this.news = response.items;
				});

			}
		},
		mounted(){
			this.query(0);
		}
	}
</script>

<style lang="scss">
	@import '../../environment';
	.o-news-summary{
		$c: &;

    &__title{
      padding: $space-m;
	    @media #{$from-small}{ background: $c-primary; color: #fff; }
    }

		@media #{$from-small}{
			position: absolute; left: 0; right:0; bottom: 0; top: 0;
			flex-direction: column; display: flex; overflow: hidden;
		}

    &__podcasts{
      cursor:pointer; padding:$space-m; border-bottom: solid 1px rgba(0,0,0,0.1); color: $c-primary; display: flex;
      span{ margin-left: auto }
      &:before{ margin-right: 1rem }
    }

    &__tv{
      cursor:pointer; padding:$space-m; border-bottom: solid 1px rgba(0,0,0,0.1); color: $c-secondary; display: flex;
      span{ margin-left: auto }
      &:before{ margin-right: 1rem }
    }

    &__all{
      cursor:pointer; padding:$space-m; border-bottom: solid 1px rgba(0,0,0,0.1); color: $c-primary; display: flex;
      span{ margin-left: auto }
      &:before{ margin-right: 1rem; font-size: 17px }
    }

		&__scrollbox {
			overflow: auto;
		}

		&__pushes {
      padding: $space-m;

      .button{
        width: 100%; text-align: center; margin-top: $space-m;
      }

      .m-news{
        &__title{ font-size: $font-m }
        @media #{$to-phone}{ margin-top: 0 }
      }

			@media #{$to-tablet}{
				max-width: 100%;
				overflow-x: auto;
				-webkit-overflow-scrolling: touch;
				display: flex;
				& > * {
					flex: 1 0 calc(80% - #{$space-s});

					& + * {
						margin-left: $space-m
					}
				}
			}
		}
	}
</style>
