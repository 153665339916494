<template>
	<nav class="o-submenu">
		<div class="container">
			<div class="o-submenu__list">
				<div  class="o-submenu__list-container" v-submenu>
					<div v-for="(item, index) in children" :key="index" v-if="config && config.menu && checkRole(user, item)">
						<router-link :to="{ name: interface.parent, params:{ section: item.slug, title: item.title }}" :key="'taxo'+item.slug" class="o-submenu__item" :class="{'parent-active': (interface.current && interface.current === url(item.url)) || ($route.params.section && $route.params.section === item.slug)}" v-if="item.taxonomy && checkRole(user, item)">
  						{{ item.title }}
						</router-link>

						<router-link :to="{ name: prefix, params: { slug: menuItem.link }}" v-for="(menuItem, menuIndex) in config.menu[item.menu]" :key="'menu'+menuIndex" class="o-submenu__item" :class="{'parent-active': interface.current && interface.current === url(menuItem.url)}" v-if="item.menu && checkRole(user, item)">
							{{ menuItem.title }}
						</router-link>

						<span v-if="!item.taxonomy && !item.menu && checkRole(user, item)">
							<a :href="item.url" class="o-submenu__item" target="_blank" v-if="item.url && item.url.substr(0, 4) === 'http'">
								{{ item.title }}
							</a>
							<router-link :to="url(item.url)" class="o-submenu__item" :class="{'parent-active': interface.current && interface.current === url(item.url),'o-submenu__item--icon':item.icon}" v-else :data-icon="item.icon">
								{{ item.title }}
							</router-link>
						</span>

					</div>
				</div>
			</div>
		</div>
	</nav>
</template>

<script>

export default {
	name: "submenu",
	props:['interface', 'data'],
	data(){
		return{
			prefix: false,
			customOrder: {
				'documents-a-signer': 1,
			},
			customRemove: ['podcasts']
		}
	},
	computed:{
		navigation(){ return this.$store.getters.navigation() },
		config(){ return this.$config()},
		user(){ return this.$user() },
		terms(){ return this.$store.getters.terms() },
		children() { 
			const children = this.navigation[this.interface.parent].children.reduce((children, child)=>{
				if(child.taxonomy)
					return [...children, ...this.filterTerms(child.taxonomy).map((term)=>({...term, ...child}))]
				else if(child.menu){
					return [...children, ...this.config.menu[item.menu].map((term)=>({...term, ...child}))]
				}
				else
					return [...children, child]
			}, [])

			let orderedChildren = children.filter((child)=>!Object.keys(this.customOrder).includes(child.url ? child.url : child.slug) && !this.customRemove.includes(child.url ? child.url : child.slug))

			Object.entries(this.customOrder).forEach(([url, index])=>{
				const item = children.find((child)=>(child.url ? child.url : child.slug) === url)
				if(item){
					orderedChildren.splice(index, 0, item)
				}
			})

			return orderedChildren
		}
	},
	methods:{
		url(element){
			return  element ? '/'+this.interface.parent+'/'+element : this.navigation[this.interface.parent].url
		},
		filterTerms(taxonomy){
			return _.orderBy(this.terms.filter(term => term.taxonomy === taxonomy), 'order')
		},
		update(response){
			this.terms = response.items
		},
	},
	created() {
		this.prefix = this.interface.prefix ?  this.interface.prefix : 'edito'
	}
}
</script>

<style lang="scss">
@import '../../environment';
.o-submenu{
	border-top: solid 1px $c-border; border-bottom: solid 1px $c-background-darker; background: white;
	&__list{
		margin: 0 auto;
		&-container{
			display: flex; width: max-content;
			& > *{ display: flex }
			& > * + *{ margin-left: $space }
		}


		@media #{$to-tablet}{
			width: 100%; overflow: hidden; overflow-x: auto; -webkit-overflow-scrolling: touch; padding: 0 $space-m $space-s;
		}
	}
	&__item{
		padding: $space-m 0; background: none; color: $c-primary; transition: allow(opacity);position:relative;
		border-radius: $border-radius; display: block;
		opacity: 0.8;
		&:after{ content: ''; position: absolute; bottom: 0; left: 0; width: 0; height: 3px; background: $c-secondary; transition: allow(width) }
		&.router-link-exact-active,&.parent-active{
			opacity: 1;font-weight: bold;
			&:after{ width: 100% }
		}

		@media #{$to-tablet}{ white-space: nowrap }

		.no-touch &:hover{ opacity: 0.8 }

		&--icon{
			display: flex;
			&:before{ margin-top: 1px; margin-right: 0.5rem }
		}
	}
	&__item + &__item{ margin-left: $space }
}
</style>
