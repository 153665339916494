<template>
	<div class="m-edit-permissions">
		<h2 class="title">Gérer les droits de mes collaborateurs</h2>
		<div>
			<table class="m-edit-permissions__table">
				<thead>
					<tr>
						<th></th>
						<th></th>
						<th>Négociateurs immobiliers</th>
						<th>Agents commerciaux</th>
						<th>Autres collaborateurs</th>
					</tr>
				</thead>
				<tbody>
				<template v-for="(row,index) in status">
					<tr v-for="(subrow,subindex) in row.substatus" :key="subrow.key" :class="{'m-edit-permissions__row':subindex===0}">
						<th v-if="subindex===0" class="m-edit-permissions__row-title subtitle">{{ row.title }}</th>
						<td v-else></td>
						<td class="m-edit-permissions__table-subtitle">{{ subrow.title }}</td>
						<td class="m-edit-permissions__action" v-for="(type,index) in types" :key="index">
						<span class="m-edit-permissions__action-cta" data-icon_after="chevron-bottom"><span :data-icon="displayRole('ROLE_'+row.key+'_'+subrow.key,type)"></span></span>
						<dropdown>
							<ul>
								<li>
									<div @click="updateRole('ROLE_'+row.key+'_'+subrow.key,type,'READ')"><span data-icon="check" v-if="roles[type] && roles[type].includes('ROLE_'+row.key+'_'+subrow.key+'_READ')" class="m-edit-permissions__selected"></span><span data-icon="read"></span>Voir</div>
								</li>
								<li>
									<div @click="updateRole('ROLE_'+row.key+'_'+subrow.key,type,'WRITE')"><span data-icon="check" v-if="roles[type] && roles[type].includes('ROLE_'+row.key+'_'+subrow.key+'_WRITE')" class="m-edit-permissions__selected"></span><span data-icon="write"></span>Editer</div>
								</li>
								<li>
									<div @click="updateRole('ROLE_'+row.key+'_'+subrow.key,type,'HIDDEN')"><span data-icon="check" v-if="roles[type] && roles[type].includes('ROLE_'+row.key+'_'+subrow.key+'_HIDDEN')" class="m-edit-permissions__selected"></span><span
											data-icon="hidden"></span>Invisible</div>
								</li>
							</ul>
						</dropdown>
						</td>
					</tr>
				</template>
				</tbody>
			</table>

			<div class="m-edit-permissions__update">
				<p>
					<b class="error">ATTENTION</b><br/>
					<span v-if="specific && specific.length > 0">
						<b>Vous avez dejà {{ specific.length }} collaborateurs</b> avec des droits spécifiques. Si vous souhaitez remplacer leurs droits actuels, par ceux ci-dessus, veuillez les sélectionner dans la liste ci-dessous.
					</span>
					<span v-else>Vous n'avez aucun collaborateur avec des droits spécifiques.</span>
				</p>
				<div v-if="specific && specific.length > 0" class="m-edit-permissions__contacts">
					<p @click="seeSpecific = !seeSpecific" class="m-edit-permissions__contacts-toggle">
						<span data-icon="chevron-bottom"></span>Voir les collaborateurs
					</p>
					<div v-if="seeSpecific">
						<p class="m-edit-permissions__contacts-title subtitle">Appliquer aux collaborateurs suivants</p>
						<ul v-if="specific && specific.length > 0" class="m-edit-permissions__contacts-list">
							<li v-for="(user,index) in specific" :key="index">
								<label class="input--checkbox">
									<input type="checkbox" v-model="override" :value="user.id"><span class="checkmark" data-icon="check"></span> {{ user.firstname }} {{ user.lastname }}
								</label>
							</li>
						</ul>
					</div>
				</div>
				<p v-else>Il n'y a aucun collaborateur à qui appliquer des droits spécifiques. Veuillez cocher la case ci-dessus pour appliquer les droits à tous les collaborateurs.</p>
				<div class="m-edit-permissions__submit">
					<form @submit.prevent="updateRoles">
						<submit class="button button--secondary" text="Modifier les droits" :loading="loading" :success="updated" text-success="Validé" :error="error"></submit>
					</form>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import companyRepository from "@/repositories/companyRepository";
import contactRepository from "@/repositories/contactRepository";

export default {
	name: "edit-permissions",
	data(){
		return{
			loading: false,
			updated: false,
			error: false,
			seeSpecific: false,
			types:['realEstateAgent','commercialAgent','other'],
			roles: {},
			override:[],
			specific: [],
			staff: false,
			id: false,
			status:[
				{
					key: "USER",
					title: "Mon compte",
					substatus:[
						{
							key: "COMPANY",
							title: "Informations relatives à la société"
						},
            {
              key: "INFO",
              title: "Informations personnelles"
            },
						{
							key: "CONTACT",
							title: "Collaborateurs"
						}
					]
				},
				{
					key: "FORMATION",
					title: "Formation",
					substatus:[
						{
							key: "SEARCH",
							title: "Rechercher les formations du SNPI"
						},
						{
							key: "SUBSCRIBE",
							title: "Souscrire à une formation et/ ou un pack"
						},
						{
							key: "ALERT_INTEREST",
							title: "Alerter le représentant légal de son intérêt pour une formation"
						},
						{
							key: "ALERT_AVAILABLE",
							title: "Être alerté si une place se libère"
						},
						{
							key: "LIST",
							title: "Lister les formations souscrites au SNPI"
						},
						{
							key: "EXTERNAL",
							title: "Ajouter et supprimer une formation externe"
						},
						{
							key: "ATTESTATION",
							title: "Télécharger les attestations de formation"
						},
						{
							key: "REPORTING",
							title: "Rapport de formation personnel"
						}
					]
				}
			]
		}
	},
	computed: {
		user() {
			return this.$user()
		}
	},
	methods:{
		updateRoles(){
			this.loading = true;
			companyRepository.update(this.id, {roles:this.roles}).then(response => {
				if(this.override.length > 0){
					let success = true
					this.override.forEach((id,index) => {
						contactRepository.update(id, {roles:false}).then(reponse => {
							if(index+1 === this.override.length){
								if(success){
									this.loading= false;
									this.updated = true
									this.$update()
								}
								else
									this.error = true
							}
						},response=>{
							success= false
								}
						)
					})
				} else {
					this.updated = true
					this.loading = false
					this.$update()
				}

			})


		},
		updateRole(status,type,action){
			if(this.roles && this.roles[type]) {
				const element = this.roles[type].find(role => role.includes(status + '_'));
				if (element) {
					const index = this.roles[type].indexOf(element)
					this.roles[type].splice(index, 1);
				}
			} else{
				this.$set(this.roles, type, [])
			}

			this.roles[type].push(status + '_' + action)
		},
		displayRole(status,type){
			if(this.roles && this.roles[type]){
				const element = this.roles[type].find(role => role.includes(status + '_'));
				return element ? element.split('_').pop().toLowerCase() : 'dot-3'
			} else
				return 'dot-3'
		}
	},
	mounted(){
		companyRepository.get().then(company=>{
			this.id = company.id
			if(company.roles &&  Object.keys(company.roles).length > 0)
				this.roles = Object.assign({}, this.roles, company.roles);
			else
				this.types.forEach(type => {
					this.$set(this.roles, type, [])
				})
		})
		companyRepository.getActiveStaff().then(response=>{
			this.loading = false;
			this.staff = response.items.filter(user => !user.isLegalRepresentative);
			// this.specific = this.staff.filter(item => item.hasCustomRoles)
		})
		companyRepository.getCustomRoles().then(response=>{
			this.specific = response.items.filter(item => item)
		})

		// let json=[];
		// this.status.forEach(item => {
		// 	item.substatus.forEach(subitem=> {
		// 		json.push('ROLE_'+item.key+'_'+subitem.key+'_READ')
		// 		json.push('ROLE_'+item.key+'_'+subitem.key+'_WRITE')
		// 		json.push('ROLE_'+item.key+'_'+subitem.key+'_HIDDEN')
		// 	})
		// })
		// console.log(json)
	}
}
</script>

<style lang="scss">
@import '../../environment';
.m-edit-permissions{
	$c: &;
	width: 80rem; max-width: 90vw; text-align: left; color: $c-primary;

	&__table{
		background: $c-background-lighter; border-top: solid 1px $c-border; border-bottom: solid 1px $c-border;

		& + *{ margin-top: $space-l }
		thead{ border-bottom: solid 1px $c-border }
		th,td{ padding: $space-s }
		th{ font-weight: bold }
		#{$c}__table-subtitle{ text-align: right; padding-left: $space-l; padding-right: $space-l; }
	}

	&__row{
		border-top: solid 1px $c-border;
		#{$c}__row-title{ padding-left: $space-l; padding-right: $space-l; white-space: nowrap; }
	}

	&__action{
		position: relative; width: 10rem;

		&-cta,.a-dropdown li{ cursor: pointer; text-align:left  }

		&-cta{
			text-align: center; border: solid 1px $c-border; border-radius: $border-radius; padding: 0.5rem;
			& > span{ font-size: 2rem; margin-right: 0.8rem }
		}

		.a-dropdown{
			margin-top: 0; right: 0; padding: 0;
			li{
				padding: 1rem 1rem 1rem 2rem;
				&+ li{ border-top: solid 1px $c-border }
			}
		}
	}
.input--checkbox{ display: flex }

	&__contacts{
		background: $c-background-lighter; padding: $space-m 0; border-top: solid 1px $c-border;
		margin-top: $space-m;
		&-list{
			max-height: 25rem; overflow: hidden; overflow-y: scroll;
			li + li{ margin-top: 0.5rem }
			li small{ font-size: $font-s; color: $c-main; margin-left: $space-s }
		}
		&-title + *{ margin-top: $space-s }

		&-toggle{
			cursor: pointer;
			span{ margin-right: $space-s }

			& + *{ margin-top: $space-m }
		}
	}

	&__update{
		padding-top: $space-m; border-top: solid 1px $c-border;

		p + p{ margin-top: $space-s }
	}

	&__submit{ text-align: right; margin-top: $space-s }

	&__selected{ position: absolute; left: 0.5rem }
}

</style>