<template>
	<div class="o-signatures-clap">
		<div class="block">
			<h2 class="title o-signatures-clap__title" v-if="interface">
				{{ interface.title }} avec Clap.legal
			</h2>
      <div class="o-signatures-clap__img-container">
        <!-- <img src="img/clap-animation.gif" /> -->
        <p class="o-signatures-clap__subtitle">Profitez de notre partenariat avec clap.legal, <strong>1 compte offert</strong> pour générer et signer vos documents juridiques</p>
        <p>Clap.legal est une solution de la suite clap.tech : connectez-vous ou créez un compte</p>
        <div class="o-signatures-clap__double_btn_container">
          <div v-if="hasToConnect" id="div_container_button_claptech"></div>
          <button v-else class="o-signatures-clap__div_container_clap-connected" @click="toClap">Accéder à Clap</button>
        </div>
        <img src="../../../public/img/clap-animation.gif" />
        <div class="clap-icons">
          <img src="/img/logo-snpi.svg" alt="essai" />
          <span>X</span>
          <img src="/img/logo-clap.legal.svg" alt="essai" />
        </div>

      </div>
		</div>
	</div>
</template>

<script>

  import userRepository from "@/repositories/userRepository";
  import editableDocumentRepository from "@/repositories/editableDocumentRepository";

  export default {
		name: "signatures-clap",
		props:['data', 'interface'],
		data(){
			return{
        hasToConnect: true,
			}
		},
    computed: {
      config() { return this.$config(); },
      user(){ return this.$user() },
      clap_token(){
        if (this.user.clap_token.length>0)
          return true;
        else
          return false;
      }
    },
		methods:{
			importClapLib(){
        let script = document.createElement('script')
        script.setAttribute('src', process.env.VUE_APP_CLAP_LIB)
        document.head.appendChild(script)
			},
      initClap(){
        let recaptchaScriptIsReady = false;
        let email = this.user.contact.email;
        let router = this.$router;
        const self = this
        let checkInterval = setInterval(function () {
          if (typeof InitClaptechLogin !== "undefined" && self.hasToConnect) {
            recaptchaScriptIsReady = true;
            clearInterval(checkInterval);
            InitClaptechLogin(process.env.VUE_APP_CLAP_PARTNERID,"div_container_button_claptech",{
              'theme': 'classic',
              'forceEmailRegister': email
            });

            document.getElementById("div_container_button_claptech").addEventListener('claptech_success', (e) => {
              console.log(e.detail)
              if (e.detail.token) {
                let claptechbtn = document.querySelector(".claptech_login_btn");
                claptechbtn.innerHTML = 'Connection réussie !';
                claptechbtn.style.backgroundColor = '#67CC9AFF';
                claptechbtn.style.border = 'none';
              }
              userRepository.setClapToken(e.detail.token).then(response => {
                location.href = '/documents/documents-a-signer';
              });
            })
          }
          return recaptchaScriptIsReady;
        }, 500);
      },
      isConnected(){
        if (this.user.clap_token.length>0) {
          this.hasToConnect = false;
        }
      },
      toClap(){
        if(this.clap_token) {
          editableDocumentRepository.getToken().then(response => {
            if (response.token) {
              window.open(process.env.VUE_APP_CLAP_FRONT_DOMAIN + '/?token_otp=' + response.token, '_blank');
            }
          })
        }
      }
		},
    mounted(){
			this.importClapLib();
      this.isConnected();
      this.initClap();
		}
	}
</script>

<style lang="scss">
	@import '../../environment';
	.o-signatures-clap{
		position:relative; min-height: 30rem;
		&__title{
			display: flex; align-items: center;
		}
    .clap-icons {
      display: none;
    }
    p{ padding: 10px 20px; text-align: center; }
    &__subtitle{
      font-weight: bold; color: $c-primary; font-size: $font-xl;
      strong{ color: #659cff; }
    }
    &__double_btn_container{
      width: 100%;
      display: flex;
      justify-content: center;
    }
    &__div_container_clap-connected {
      width: 30%;
      margin-top: 10px;
      border-radius: 14px;
      background-color: #67CC9AFF;
      color: white;
      padding: $space-m;
      display: flex;
      justify-content: center;
      font-size: $font-l;
      border: none;
      cursor: pointer;
      @media #{$to-tablet}{
        width: 100%;
      }
    }
    #div_container_button_claptech{
      text-align: center;
      .claptech_login_btn{
        margin-top: 10px;
        color: white;
        background-color: $c-primary;
        border: solid 1px $c-primary;
        &:hover{
          background-color: $c-primary;
          border: solid 1px $c-primary;
        }
      }
    }
		&__order{ margin-left: auto }
    &__loader.a-loader{ top: auto; bottom: -5.5rem; box-shadow: none }
  }

</style>