<template>
  <div class="m-podcast">
    <div class="m-podcast__thumbnail" v-if="data.thumbnail">
      <picture>
        <source media="(max-width: 767px)" :srcset="data.thumbnail+'?w=400&h=200'">
        <img :src="data.thumbnail+'?w=200&h=200'"/>
      </picture>
    </div>
    <div class="m-podcast__info">
      <h2 class="m-podcast__title">
        <span class="m-podcast__subtitle" v-if="data.assets[0].title!=='Mansuel'">{{ data.assets[0].title }}</span>
        {{ data.title }}
      </h2>
      <div class="m-podcast__date">
        {{ formatDate(data.createdAt) }} • {{ data.assets[0].description }}
      </div>
      <div class="m-podcast__description">
        {{ data.description }}
      </div>
      <div class="m-podcast__cta">
        <a class="button" :class="{'button--success':podcast.id===data.id}" @click="play">
          <span v-if="podcast.id===data.id">En cours d'écoute</span>
          <span v-else>Écouter</span>
        </a>
        <form v-if="data.assets.length>1" @submit.prevent="downloadAsset(1)">
          <submit :loading="downloading" text="Lire le podcast" class="button--border"></submit>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import documentRepository from "../../repositories/documentRepository";

export default {
  name: "podcast",
  props:['data'],
  data(){
    return{
      downloading: false
    }
  },
  methods:{
    play(){
      this.$store.commit('podcast', this.data)
    },
    downloadAsset(index){
      this.downloading = true;
      documentRepository.download(this.data.id, index).then(
          response=>{
            this.downloading = false;
          },
          response=>{
            this.downloading = false;
          });
    },
  },
  computed:{
    podcast(){
      return this.$store.getters.podcast()
    }
  }
}
</script>

<style lang="scss">

@import '../../environment';

.m-podcast{
  display: flex; padding: 2rem;
  @media #{$to-phone}{ flex-direction: column }

  &__thumbnail{
    margin-right: 2rem; flex-shrink: 0;
    @media #{$to-phone}{ margin-right: 0; margin-bottom: 2rem }
    img{
      width: 20rem; height: 20rem; border-radius: $border-radius;
      @media #{$to-phone}{ width: 100%; height: auto }
    }
  }
  &__title{
    font-size: $font-xxl; color: $c-primary; font-weight: bold; margin-bottom: 1rem;
    span{ display: block; font-size: $font-l; font-weight: normal; color: $c-main }
  }
  &__date{
    font-size: $font-m; margin-bottom: 2rem;
  }
  &__description{
    font-size: $font-m; color: $c-main; margin-bottom: 2rem;
  }
  &__info{ display: flex; flex-direction: column }
  &__cta{
    margin-top: auto;
    form{ display: inline; margin-left: 1rem }
  }
}
</style>
