<template>
	<div class="m-edit-contact-permissions">
    <span v-if="dirtyWarning" class="m-edit-contact-permissions__warning">Attention : Les changements apportés n'ont pas été sauvegardés, veuillez cliquez sur "Modifier les droits"</span>    
		<div>
			<table class="m-edit-contact-permissions__table" v-if="contact">
				<thead>
					<tr>
						<td colspan="2">
							<div class="m-edit-contact-permissions__contact">
								<div class="m-edit-contact-permissions__thumbnail">
									<div>
										<img :src="contact.avatar" v-if="contact.avatar" />
										<span class="m-edit-contact-permissions__initial" v-else><span v-if="contact.firstname">{{ contact.firstname.substr(0,1) }}</span>{{ contact.lastname.substr(0,1) }}</span>
									</div>
								</div>
								<div class="m-edit-contact-permissions__name">
									<div class="title-big"><span v-if="contact.firstname">{{contact.firstname}}</span> {{contact.lastname}}</div>
								</div>
							</div>
						</td>
						<th></th>
						<th class="m-edit-contact-permissions__table-position">{{ this.roleTitle }}</th>
					</tr>
				</thead>
				<tbody>
				<template v-for="(row,index) in status">
					<tr v-for="(subrow,subindex) in row.substatus" :key="subrow.key" :class="{'m-edit-contact-permissions__row':subindex===0}">
						<th v-if="subindex===0" class="m-edit-contact-permissions__row-title subtitle">{{ row.title }}</th>
						<td v-else></td>
						<td class="m-edit-contact-permissions__table-subtitle">{{ subrow.title }}</td>
						<td class="m-edit-contact-permissions__action">
						<span class="m-edit-contact-permissions__action-cta" data-icon_after="chevron-bottom"><span :data-icon="displayRole('ROLE_'+row.key+'_'+subrow.key)"></span></span>
						<dropdown>
							<ul>
								<li>
									<div @click="updateRole('ROLE_'+row.key+'_'+subrow.key,'READ')"><span data-icon="check" v-if="roles.includes('ROLE_'+row.key+'_'+subrow.key+'_READ')"></span><span v-else data-icon="read"></span>Voir</div>
								</li>
								<li>
									<div @click="updateRole('ROLE_'+row.key+'_'+subrow.key,'WRITE')"><span data-icon="check" v-if="roles.includes('ROLE_'+row.key+'_'+subrow.key+'_WRITE')"></span><span v-else data-icon="write"></span>Editer</div>
								</li>
							</ul>
						</dropdown>
						</td>
						<td class="m-edit-contact-permissions__action">
						<span class="m-edit-contact-permissions__action-cta m-edit-contact-permissions__action-cta--disabled"><span :data-icon="displayGlobalRole('ROLE_'+row.key+'_'+subrow.key)"></span></span>
						</td>
					</tr>
				</template>
				</tbody>
			</table>
			<span v-if="dirtyWarning" class="m-edit-contact-permissions__warning">Attention : Les changements apportés n'ont pas été sauvegardés, veuillez cliquez sur "Modifier les droits"</span>
			<div class="m-edit-contact-permissions__update">
				<div class="m-edit-contact-permissions__submit">
					<form @submit.prevent="updateContactRole">
						<submit class="button button--primary" text="Modifier les droits" :loading="loading" :success="updated" text-success="Validé"></submit>
					</form>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import companyRepository from "@/repositories/companyRepository";
import contactRepository from "@/repositories/contactRepository";

export default {
	name: "edit-contact-permissions",
	data(){
		return{
			loading: false,
			updated: false,
			roles: [],
			globalRoles: [],
			roleTitle: false,
			contact: false,
			type:['realEstateAgent', 'commercialAgent', 'other'],
			status:[
				{
					key: "USER",
					title: "Mon compte",
					substatus:[
						{
							key: "COMPANY",
							title: "Informations relatives à la société"
						},
						{
							key: "INFO",
							title: "Informations personnelles"
						},
						{
							key: "CONTACT",
							title: "Collaborateurs"
						}
					]
				},
				{
					key: "FORMATION",
					title: "Formation",
					substatus:[
						{
							key: "SEARCH",
							title: "Rechercher les formations du SNPI"
						},
						{
							key: "SUBSCRIBE",
							title: "Souscrire à une formation et/ ou un pack"
						},
						{
							key: "ALERT_INTEREST",
							title: "Alerter le représentant légal de son intérêt pour une formation"
						},
						{
							key: "ALERT_AVAILABLE",
							title: "Être alerté si une place se libère"
						},
						{
							key: "LIST",
							title: "Lister les formations souscrites au SNPI"
						},
						{
							key: "EXTERNAL",
							title: "Ajouter et supprimer une formation externe"
						},
						{
							key: "ATTESTATION",
							title: "Télécharger les attestations de formation"
						},
						{
							key: "REPORTING",
							title: "Rapport de formation personnel"
						}
					]
				}
			]
		}
	},
	props: ['data', 'onDirtyChange', 'dirtyWarning'],
	computed: {
		user() {
			return this.$user()
		}
	},
	methods:{
		close(){
			this.$update()
			this.$emit('close')
		},
		updateRole(status,action){
			this.onDirtyChange(true);
			if(this.roles) {
				const element = this.roles.find(role => role.includes(status + '_'));
				if (element) {
					const index = this.roles.indexOf(element)
					this.roles.splice(index, 1);
				}
			}

			this.roles.push(status + '_' + action)
		},
		updateContactRole(){
			contactRepository.update(this.contact.id, {roles:this.roles}).then(response => {

				this.loading = false;
				this.updated = true;

				setTimeout(this.close, 1500);

				this.onDirtyChange(false);

			}, e => {
				this.loading = false;
			})
		},
		displayRole(status){
			if(this.roles){
				const element = this.roles.find(role => role.includes(status + '_'));
				return element ? element.split('_').pop().toLowerCase() : 'dot-3'
			} else
				return 'dot-3'
		},
		displayGlobalRole(status){
			if(this.globalRoles){
				const element = this.globalRoles.find(role => role.includes(status + '_'));
				return element ? element.split('_').pop().toLowerCase() : 'dot-3'
			} else
				return 'dot-3'
		},
		getBaseRole(role){
			return role.replace("_READ", "").replace("_WRITE", "").replace("_HIDDEN", "")
		}
	},
	mounted(){
		contactRepository.get(this.data.id).then(contact => {
			this.contact = contact
			if(this.contact.hasCustomRoles)
				this.roles = [...this.contact.roles]

			companyRepository.get().then(company=>{
				this.id = company.id
				if(company.roles &&  Object.keys(company.roles).length > 0){
					if(this.contact.positions.isRealEstateAgent){
						this.globalRoles = company.roles.realEstateAgent
					} else if(this.contact.positions.isCommercialAgent){
						this.globalRoles = company.roles.commercialAgent
					} else {
						this.globalRoles = company.roles.other
					}
				}

				const customBaseRoles = this.roles.map(this.getBaseRole)

				this.globalRoles.forEach((globalRole)=>{
					if(!customBaseRoles.includes(this.getBaseRole(globalRole))){
						this.roles.push(globalRole)
					}
				})

				if(this.contact.positions.isRealEstateAgent){
					this.roleTitle = "Négociateur immobilier"
				} else if(this.contact.positions.isCommercialAgent){
					this.roleTitle = "Agent commercial"
				} else {
					this.roleTitle = "Autre collaborateur"
				}
			})
		})

	}
}
</script>

<style lang="scss">
@import '../../environment';
.m-edit-contact-permissions{
	$c: &;
	width: 80rem; max-width: 90vw; text-align: left; color: $c-primary;

	&__table{
		background: $c-background-lighter; margin-top: $space-m;

		& + *{ margin-top: $space-l }
		thead{
      border-bottom: solid 1px $c-border;
      td{ padding: $space-m }
    }
		th,td{ padding-bottom: 1rem; vertical-align: middle }
		th{ font-weight: bold }
		#{$c}__table-subtitle{ text-align: right; padding-left: $space-l; padding-right: $space-m; }

		#{$c}__table-position{ font-weight: normal; opacity: 0.5; font-size: 10px; text-align: center }
	}

	&__row{
		border-top: solid 1px $c-border;
    td { padding-top: 1rem }
		#{$c}__row-title{ padding-left: $space-l; padding-right: $space-l; white-space: nowrap; }
	}

	&__action{
		position: relative; width: 10rem; text-align: center;

		&-cta,.a-dropdown li{ cursor: pointer; text-align:left }

		&-cta{
			text-align: center; border: solid 1px $c-border; border-radius: $border-radius; padding: 0.5rem; display: inline-block;
			& > span{ font-size: 1.8rem; margin-right: 0.8rem }

			&--disabled{
				opacity: 0.5; cursor: default;
				& > span{ margin-left: 0.8rem }
			}
		}

		.a-dropdown{
			margin-top: 0; right: 50%; padding: 0; transform: translateX(3.5rem);
			li{
				padding: 1rem 2rem 1rem 2rem;
				&+ li{ border-top: solid 1px $c-border }
			}
		}
	}

	&__update{
		padding-top: $space-m; border-top: solid 1px $c-border;

		p + p{ margin-top: $space-s }
	}

	&__submit{ text-align: right; margin-top: $space-s }

	&__contact{ display: flex; align-items: center }

	&__thumbnail{
		width: 40px; padding-top: 40px; position: relative; border-radius: 50%; overflow:hidden;
		transition: allow(opacity); margin-right: $space-m;

		&:before{ position: overlay(); content: ''; background: $c-primary; z-index: 1;  opacity: 0.4 }

		img{ position:overlay(); object-fit: cover; height: 100%; width: 100% }
	}

	&__initial{
		position: absolute; top: 0; left: 0; width: 100%; height: 100%; display: flex; justify-content: center;
		align-items: center; color: $c-primary; font-size: 2.2rem; opacity: 0.2; font-weight: bold; text-transform: uppercase;
	}

	&__selected{ position: absolute; left: 0.5rem }
	&__warning{
		display: block;
		color: $c-red;
		margin-block: $space-s;
		text-align: center;
	}
}

</style>
